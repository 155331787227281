import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import IconSkill from '@components/atoms/icons/icon-skill'
import useStyles from './coach-skills-tools.styles'

const CoachSkillsTools = (props) => {
  const {
    firstName,
    skills,
    tools,
  } = props
  const styles = useStyles()
  const [isSkillsSelected, setIsSkillsSelected] = useState(true)

  return (skills & skills.length > 0) && (tools && tools.length > 0) ? (
    <Box className={styles.containerWrapper}>
      <Container>
        <Box className={styles.titleWrapper}>
          <Typography variant="overline">Skills & Tools</Typography>
          <Typography variant="h4" style={{ marginBottom: '1.5rem' }}>Improve with {firstName}.</Typography>
        </Box>
        <Box className={styles.tabsWrapper}>
          <Typography
            variant="overline"
            className={`${styles.titleRoundedBG} ${isSkillsSelected && styles.selectedTab}`}
            onClick={() => setIsSkillsSelected(true)}
          >
            Skills
          </Typography>
          {
            tools && tools.length > 0 ? (
              <Typography
                variant="overline"
                className={`${styles.titleRoundedBG} ${!isSkillsSelected && styles.selectedTab}`}
                onClick={() => setIsSkillsSelected(false)}
              >
                Tools
              </Typography>
            ) : null
          }
        </Box>

        { isSkillsSelected && skills && skills.length ? (
          <div className={styles.skillsWrapper}>
            {
              skills.map((skill) => {
                return (
                  <Box key={skill.skillName} className={styles.skillItem}>
                    <IconSkill style={{ width: '40px', height: '40px' }} type={skill.skillName} />
                    <Typography variant="subtitle1">{skill.skillName}</Typography>
                  </Box>
                )
              })
            }
          </div>
        ) : null}
        { !isSkillsSelected && tools && tools.length ? (
          <div className={styles.skillsWrapper}>
            {
              tools.map((tool) => {
                return (
                  <Box key={tool.toolItem} className={`${styles.skillItem} ${styles.toolItem}`}>
                    <Typography variant="subtitle1">{tool}</Typography>
                  </Box>
                )
              })
            }
          </div>
        ) : null}
      </Container>
    </Box>
  ) : null
}

CoachSkillsTools.defaultProps = {}

export default CoachSkillsTools
