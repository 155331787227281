import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import { path } from 'ramda'
import LinearProgress from '@material-ui/core/LinearProgress'
import ContentUnavailable from '@components/content-unavailable'
import Error from '@components/error'
import { getProfileSchema } from '@schema/coach-profile'
import CoachPage from './coach-page'

const COACH_QUERY = gql`
  query CoachProfiles($customSlug: String) {
    CoachProfiles(customSlug: $customSlug) {
      message,
      profiles {
        ${getProfileSchema}
      }
    }
  }
`

export default function CoachPageWithData(props) {
  const { query } = props
  const { slug } = query

  return (
    <Query query={COACH_QUERY} variables={{ customSlug: slug }} context={{ clientName: 'coach-tools' }}>
      {({
        loading, error, data,
      }) => {
        if (error) {
          return <Error error={error} displayErrorType="page" />
        }
        if (loading) return <LinearProgress color="secondary" />
        const coachProfile = path(['CoachProfiles', 'profiles', 0], data)

        if (!coachProfile) return <Error statusCode={404} />
        if (!coachProfile.coach.pgaCoachEligible) return <ContentUnavailable />

        return <CoachPage coach={coachProfile} />
      }}
    </Query>
  )
}

CoachPageWithData.propTypes = {
  query: PropTypes.shape({
    slug: PropTypes.string,
  }),
}
