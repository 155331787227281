import { makeStyles } from '@material-ui/core/styles'

const ratio = '56.25%'

const useStyles = makeStyles(() => ({
  embed: {
    position: 'relative',
    paddingBottom: ratio,
    height: 0,
    overflow: 'hidden',
    maxWidth: '100%',
    zIndex: 2,
    '& embed, & iframe, & object': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
    },
  },
}))

export default useStyles
