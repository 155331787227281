import { styled, makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

export const Root = styled(Box)(({ theme }) => ({
  width: '100%',
  paddingBottom: theme.spacing(5),
  backgroundColor: 'white',
  color: 'black',
}))

export const FormFacilityWrapper = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(8),
  display: 'flex',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignContent: 'top',
  },
}))

export const ContactForm = styled(Box)(({ theme }) => ({
  display: 'table',
  textAlign: 'center',
  width: '450px',
  padding: '40px 30px',
  backgroundColor: theme.palette.secondary.main,
  backgroundSize: 'cover',
  borderRadius: '4px',
  color: 'white',

  '& .caps': {
    textTransform: 'uppercase',
    letterSpacing: '0.0625em',
    fontWeight: 600,
  },

  [theme.breakpoints.down('md')]: {
    display: 'block',
    margin: '2rem auto 0 auto',
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%',
  },
}))

export const FacilitiesWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  width: '100%',
  paddingRight: '1.5rem',

  [theme.breakpoints.down('md')]: {
    width: '100%',
    paddingRight: 0,
  },
}))

export const OfferingsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  [theme.breakpoints.up('sm')]: {
    '& div:nth-child(odd)': {
      marginRight: '2rem',
    },
  },
}))

export const Offering = styled(Box)(({ theme }) => ({
  width: 'calc(50% - 1rem)',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

export const FlexRow = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}))

export const BoldText = styled(Typography)(() => ({
  display: 'flex',
  fontWeight: '600',
}))

export const LightText = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(3),
  fontSize: '1rem',
  color: theme.palette.grey.dark,
}))

export const useStyles = makeStyles({
  link: {
    textDecoration: 'underline',
  },
})
