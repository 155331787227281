import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    color: 'white',
  },
  containerWrapper: {
    backgroundImage: 'linear-gradient(to bottom, #043362, #00234b)',
    color: 'white',
  },
  servicesSection: {
    display: 'flex',
    paddingTop: '3rem',
    paddingBottom: '3rem',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  titleWrapper: {
    display: 'inline-block',
    width: '33%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  serviceWrapper: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
  },
  allServices: {
    display: 'inline-block',
    width: '66%',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      width: '100%',
      maxWidth: '400px',
      marginTop: theme.spacing(4),
    },
  },
  serviceEntry: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '1rem',
  },
  service: {
    display: 'flex',
    alignItems: 'middle',
    paddingBottom: theme.spacing(2),
  },
  serviceName: {
    paddingLeft: theme.spacing(1),
    fontSize: '0.875rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    letterSpacing: '0.125rem',
  },
}))

export default useStyles
