import React from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { IconMarker } from '@components/atoms/icons'
import LinkIcon from '@material-ui/icons/Link'
import Link from '@components/link'
import { useTheme } from '@material-ui/core/styles'
import { path, or } from 'ramda'
import {
  Root,
  FormFacilityWrapper,
  FacilitiesWrapper,
  OfferingsWrapper,
  Offering,
  ContactForm,
  FlexRow,
  LightText,
  BoldText,
  useStyles,
} from './coach-facilities.styles'
import useIsMobile from '@hooks/useIsMobile'
import { gtmEvent } from '@lib/gtm-utils'

const FacilityContent = ({ facility }) => {
  const offerings = path(['customData', 'offerings'], facility)
  const description = path(['customData', 'description'], facility)
  const offersCoachingAtPrivateClub = path(['customData', 'offersCoachingAtPrivateClub'], facility)
  const isCoachingAvailable = or(!facility.isPrivate, offersCoachingAtPrivateClub)
  // Search course by address, encode fields for iframe embed uri
  const mapSearchAddress = encodeURIComponent(`${facility.street} ${facility.zipCode}`)

  const classes = useStyles()
  const theme = useTheme()
  return (
    <>
      <Box>
        <LightText style={{ marginTop: 0 }}>{`${facility.city} ${facility.state}`}</LightText>
      </Box>
      {
        facility.customData && facility.customData.photos && facility.customData.photos.length && facility.customData.photos[0] ? (
          <img
            src={facility.customData.photos[0]}
            alt="Facility"
            style={{ marginBottom: '1rem', width: '100%' }}
          />
        ) : null
      }
      {
        description ? (
          <Typography variant="body2" style={{ fontSize: '1rem' }}>{description}</Typography>
        ) : null
      }
      {
        isCoachingAvailable && (
          <LightText>*Coaching available to public</LightText>
        )
      }
      <FlexRow style={{ alignItems: 'center' }}>
        <IconMarker style={{ marginLeft: '-8px', marginRight: '16px' }} />
        <BoldText>{`${facility.street}, ${facility.city}, ${facility.state} ${facility.zipCode}`}</BoldText>
      </FlexRow>
      {facility.website && (
        <FlexRow style={{ alignItems: 'center' }}>
          <Box display="flex" alignItems="center" mt={1} mb={1}>
            <LinkIcon fontSize="small" style={{ marginLeft: '-5px', marginRight: '16px', color: theme.palette.primary.dark }} />
            <BoldText>
              <Link externalLink={facility.website} className={classes.link}>
                {facility?.facilityName}
              </Link>
            </BoldText>
          </Box>
        </FlexRow>
      )}
      <iframe
        frameBorder="0"
        scrolling="no"
        marginHeight="0"
        marginWidth="0"
        width="100%"
        height="360"
        style={{ marginTop: '1rem' }}
        src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GOOGLE_APIS_CLIENT_KEY}&q=${mapSearchAddress}`}
        title={`${facility.facilityName} Facility Location`}
      />
      {/* Offerings */}
      {
        offerings?.length ? (
          <div style={{ marginTop: '1rem' }}>
            <Typography variant="overline" style={{ marginTop: '1rem', marginBottom: '1rem', color: theme.palette.grey.dark }}>Facility Offerings</Typography>
            <OfferingsWrapper>
              {
                offerings.map(offering => (
                  <Offering key={offering.name}>
                    <Typography variant="h6">{offering.name}</Typography>
                    <LightText>{offering.description}</LightText>
                  </Offering>
                ))
              }
            </OfferingsWrapper>
          </div>
        ) : null
      }
    </>
  )
}


FacilityContent.propTypes = {
  facility: PropTypes.shape({
    facilityName: PropTypes.string,
    city: PropTypes.string,
    description: PropTypes.string,
    offeringsJsonData: PropTypes.array,
    photosJsonData: PropTypes.array,
    state: PropTypes.string,
    street: PropTypes.string,
    isPrivate: PropTypes.bool,
    zipCode: PropTypes.string,
  }),
}

const FacilityContainer = ({ facilities }) => (
  facilities.length > 1 ? facilities.map((facility, i) => {
    return (
      <ExpansionPanel key={facility.facilityId} defaultExpanded={i === 0} style={{ marginTop: '1rem' }}>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`panel${i}bh-content`}
          id={`panel${i}bh-header`}
        >
          <Typography variant="h4" style={{ color: 'black' }}>{facility.facilityName}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails style={{ flexDirection: 'column', paddingTop: 0 }}>
          <FacilityContent facility={facility} />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }) : (
    <>
      <Typography variant="h4" style={{ color: 'black', marginTop: '1rem', marginBottom: '1rem' }}>{facilities[0].facilityName}</Typography>
      <FacilityContent facility={facilities[0]} />
    </>
  )
)

FacilityContainer.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.shape({
    facilityName: PropTypes.string,
  })),
}

const CoachFacilities = (props) => {
  const {
    facilities,
    firstName,
    lastName,
    displayName,
    profileId,
    showCTA,
  } = props
  const isMobile = useIsMobile()

  const theme = useTheme()

  const coachName = (firstName && lastName) ? `${firstName} ${lastName}` : displayName

  const scrollToForm = () => {
    const formElement = document.getElementById('lead-form')
    const offset = isMobile ? 80 : 180
    if (formElement) {
      const formPosition = formElement.offsetTop
      window.scrollTo({ top: formPosition - offset, behavior: 'smooth' })
    }
  }

  const handleContactClick = () => {
    gtmEvent({
      event: 'click-contact-button',
      attributes: {
        coach_name: coachName,
        coach_profile_id: profileId,
      },
    })
    scrollToForm()
  }

  return (
    <Root>
      <Container>
        <FormFacilityWrapper>
          {facilities && facilities.length > 0
            ? (
              <FacilitiesWrapper>
                <Typography variant="overline" style={{ color: theme.palette.grey.dark, lineHeight: 1 }}>{`${firstName}'s Facilities`}</Typography>
                <FacilityContainer facilities={facilities} />
              </FacilitiesWrapper>
            )
            : null}
          {showCTA ? (
            <ContactForm>
              <span className="caps">Begin Your Journey</span>
              <Typography variant="h4" style={{ marginTop: '1.5rem', marginBottom: '3rem' }}>Work with {firstName}</Typography>
              <Button
                onClick={handleContactClick}
                variant="outlined"
                style={{ width: '100%', color: 'white', border: '1px solid white' }}
                data-gtm-button-name="consolidated-contact-bookable-coach"
                data-gtm-profile-coach={coachName}
                data-gtm-cta-position="footer"
              >
                Contact {firstName}
              </Button>
            </ContactForm>
          ) : null}
        </FormFacilityWrapper>
      </Container>
    </Root>
  )
}

CoachFacilities.propTypes = {
  facilities: PropTypes.array,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  displayName: PropTypes.string,
  profileId: PropTypes.string,
  customSlug: PropTypes.string,
  showCTA: PropTypes.bool.isRequired,
}

CoachFacilities.defaultProps = {}

export default CoachFacilities
