// Schema used for retrieving a coach
export const getProfileSchema = `
  coachProfileId
  firstName
  lastName
  displayName
  title
  gender
  profilePhoto
  customSlug
  phoneExt
  mobileNumber
  city
  state
  zipCode
  bio
  numberOfStudents
  numberOfFollowers
  bookingUrl
  bookable
  status
  certificationLevel
  memberType
  socialJsonData {
    type
    reference
    preferred
  }
  testimonialsJsonData {
    testimonial
    author
    rank
  }
  photosJsonData {
    reference
    rank
  }
  toolJsonData
  facilities {
    facilityId
    pgaFacilityId
    facilityName
    street
    city
    state
    zipCode
    phoneNumber
    website
    isPrivate
    customData {
      facilityRank
      description
      photos
      offerings {
        name
        description
      }
      offersCoachingAtPrivateClub
    }
  }
  experienceTypes {
    experienceTypeId
    typeName
    description
    experiences {
      experienceId
      experienceName
      experienceType
    }
  }
  serviceTypes {
    serviceTypeId
    typeName
    description
    services {
      serviceId
      serviceName
      serviceType
    }
  }
  skills {
    skillId
    skillName
    description
  }
  coach {
    pgaCoachEligible
    canPromoteContent
    id
    preferences {
      bookNowEnabled
    }
    lessonTypes {
      id
      status
    }
  }
  universalId
  introductionVideoUrl
`
