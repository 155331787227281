import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { IconCheckMark } from '@components/atoms/icons'
import useStyles from './coach-experiences.styles'

const CoachExperiences = (props) => {
  const {
    firstName,
    experiences,
  } = props
  const styles = useStyles()

  return experiences && experiences.length ? (
    <Box className={styles.root}>
      <Container className={styles.container}>
        <Typography variant="overline" className={styles.title}>{firstName && `${firstName}'s Experience`}</Typography>
        <Typography variant="h4">Helping players achieve their goals.</Typography>
        {
          experiences.map((experience) => {
            return (
              <React.Fragment key={`Fragment:${experience.typeName}`}>
                <hr className={styles.hr} />
                <Box key={`Box:${experience.typeName}`} className={styles.experienceWrapper}>
                  <Typography variant="h5" className={styles.experienceTitle}>{experience.typeName}</Typography>
                  <Box className={styles.experienceType}>
                    {
                      experience.experiences.map((value) => {
                        return (
                          <Box key={value.experienceName} className={styles.experienceEntry}>
                            <IconCheckMark style={{ marginLeft: '-0.2em' }} />
                            <Typography variant="body2" className={styles.experienceName}>{value.experienceName}</Typography>
                          </Box>
                        )
                      })
                    }
                  </Box>
                </Box>
              </React.Fragment>
            )
          })
        }

      </Container>
    </Box>
  ) : null
}

CoachExperiences.defaultProps = {}

export default CoachExperiences
