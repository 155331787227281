import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Slider from 'react-slick'
import SliderNew from '@components/slider-new'
import Hidden from '@material-ui/core/Hidden'
import useStyles from './coach-photos.styles'
import { gtmEvent } from '@lib/gtm-utils'

const CoachPhotos = (props) => {
  const {
    firstName,
    photos,
  } = props

  const transformHeicToJpg = (reference) => {
    return reference.replace('.heic', '.jpg')
  }

  const styles = useStyles({ isPhotos: true, blackPagination: true })
  const slides = photos.map(photo => (
    <div key={`photo-${photo.rank}`}>
      <div className={styles.slideWrapper}>
        <img
          className={styles.slideImage}
          src={transformHeicToJpg(photo.reference)}
          alt=""
        />
      </div>
    </div>
  ))

  const settings = {
    dots: photos.length > 1,
    infinite: true,
    centerMode: photos.length > 0 && true,
    centerPadding: photos.length > 1 && '200px',
    slidesToShow: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 720,
        settings: {
          centerMode: false,
        },
      },
    ],
    afterChange(i) {
      gtmEvent({ event: 'click-coach-photos', attributes: { number: i + 1 } })
    },
    customPaging(i) {
      return (
        <a className={styles.paginationNumber}>
          {i + 1}
        </a>
      )
    },

  }
  const config = {
    initial: 0,
    loop: true,
    mode: 'snap',
    slides: { origin: 'center', perView: 1, spacing: 8 },
    breakpoints: {
      '(min-width: 600px)': {
        loop: false,
        slides: { perView: 'auto', spacing: 28 },
      },
    },
  }

  return photos && photos.length && (
    <>
      <Hidden implementation="css" smUp>
        <Box display="flex" flexDirection="column" alignItems="center" pt={4}>
          <Typography variant="overline" className={styles.mobileAction}>
            {`${firstName} in Action`}
          </Typography>
          <Typography variant="h2" style={{ color: '#000' }}>Recent Photos</Typography>
          <SliderNew
            controlClassName={styles.customControl}
            config={config}
          >
            {slides.map(slide => (
              <div key={slide.key} className={styles.slideWrapper}>
                {slide}
              </div>
            ))}
          </SliderNew>
        </Box>
      </Hidden>
      <Hidden implementation="css" xsDown>
        <Box className={styles.testimonialsWrapper}>
          <Typography variant="overline" style={{ color: '#6a6a6a', marginBottom: '0.5rem' }}>{`${firstName} in Action`}</Typography>
          <Typography variant="h4">Recent Photos</Typography>
          <Slider
            {...settings}
            className={styles.slideWrapper}
          >
            {slides}
          </Slider>
        </Box>
      </Hidden>
    </>
  )
}


CoachPhotos.propTypes = {
  firstName: PropTypes.string,
  photos: PropTypes.array,
}

CoachPhotos.defaultProps = {}

export default CoachPhotos
