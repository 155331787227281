import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  testimonialsWrapper: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(4),
    textAlign: 'center',
    color: 'black',
    background: props => (props.isPhotos ? 'white' : `center/cover url(${props.bgURL}), ${theme.palette.secondary.main}`),

    '& .slick-slider': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginBottom: '-3rem',

      '& .slick-arrow': {
        position: 'relative',
        left: 'unset',
        right: 'unset',
        transform: 'unset',
        margin: '-50px 48px',
        zIndex: 2,

        '&:before': {
          fontFamily: 'unset',
          content: 'unset',
        },
      },

      '& .slick-list': {
        order: 1,
        width: '100%',
        marginBottom: props => props.isPhotos && theme.spacing(2),
      },


      '& .slick-dots': {
        bottom: '20px',
        zIndex: '1000',
      },
      // add photos side image effects
      '& .slick-slide': {
        [theme.breakpoints.up('md')]: {
          display: props => props.isPhotos && 'flex',
          justifyContent: props => props.isPhotos && 'center',
          transition: 'margin-left 0.5s, margin-right 0.5s',
        },
      },
      '& .slick-slide img': {
        transform: 'scale(0.9)',
        opacity: 0.5,
        transition: 'transform 0.5s, opacity 0.5s',
      },
      '& .slick-center img, & .slick-active img': {
        transform: 'scale(1)',
        opacity: 1,
        transition: 'transform 0.5s, opacity 0.5s',
      },
      // on larger screen sizes, position side photos towards the 'active' slide
      '& .slick-cloned:nth-child(1), & .slick-cloned:nth-child(2):not(.slick-center), & .slick-slide:not(.slick-center):not(.slick-cloned)': {
        // left slide on large screens
        [theme.breakpoints.up('lg')]: {
          /* calc = full window width
           *      - photo max width
           *      - slide's "centerPadding" config on either side
           */
          marginLeft: props => props.isPhotos && 'calc(100vw - 800px - 200px * 2)',
          marginRight: props => props.isPhotos && 'calc(-100vw + 800px + 200px * 2)',
        },
      },
      '& .slick-center + .slick-slide': {
        // right slide on large screens
        [theme.breakpoints.up('lg')]: {
          marginLeft: props => props.isPhotos && 'calc(-100vw + 800px + 200px * 2) !important',
          marginRight: props => props.isPhotos && 'calc(100vw - 800px - 200px * 2) !important',
        },
      },
    },
  },
  slideWrapper: {
    paddingBottom: '8px',
    '& .slick-slide': {
      padding: '30px 0px',
      '& > div': {
        paddingLeft: '14px',
        paddingRight: '14px',
      },
      [theme.breakpoints.up('sm')]: {
        paddingBottom: '48px',
      },
    },
    '& .slick-list': {
      margin: '0 auto',
      width: 'calc( 100% + 28px)',
      marginLeft: '-14px',
    },
    '& .slick-dots': {
      bottom: '18px',
    },
    '& .slick-dots li': {
      width: '40px',
      height: '40px',
      margin: '0',
    },
    '& .slick-active > a': {
      backgroundColor: '#00234B',
      color: theme.palette.primary.contrastText,
    },
    '& .slick-next': {
      display: 'none!important',
    },
    '& .slick-prev': {
      display: 'none!important',
    },
  },
  slide: {
    width: '100%',
    height: props => !props.isPhotos && '500px',
  },
  testimony: {
    color: 'white',
  },
  author: {
    paddingTop: theme.spacing(2),
    color: '#ffbe00',
  },
  slideImage: {
    width: '100%',
    maxWidth: '800px',
    borderRadius: '4px',
  },
  paginationNumber: {
    fontFamily: 'Source Serif Pro',
    fontSize: '18px',
    fontWeight: '700',
    letterSpacing: '0.02em',
    textAlign: 'center',
    textDecoration: 'none',
    color: theme.palette.grey.dark,
    padding: theme.spacing(2),
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100%',
  },
  mobileAction: {
    color: '#6a6a6a',
    fontSize: '12px',
    fontFamily: 'Nunito Sans',
    letterSpacing: '1px',
  },
}))

export default useStyles
