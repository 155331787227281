import React from 'react'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import Typography from '@material-ui/core/Typography'

const Recaptcha = ({ setToken, error }) => (
  <div style={{ marginBottom: '10px' }}>
    <ReCAPTCHA
      sitekey={process.env.RECAPTCHA_V2_KEY}
      onChange={token => setToken(token)}
    />
    {error && <Typography style={{ color: 'red', fontSize: '12px' }} />}
  </div>
)

Recaptcha.propTypes = {
  setToken: PropTypes.func,
  error: PropTypes.string,
}

export default Recaptcha
