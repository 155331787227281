import PropTypes from 'prop-types'
import React from 'react'
import { NextSeo } from 'next-seo'
import ProfilePage from '@components/coaches-page/coaches-page'
import defaults from '@config/defaults'
import { JsonLd } from '@components/atoms'


const CoachPage = (props) => {
  const {
    coach: profile,
  } = props

  const coachName = profile.displayName || `${profile.firstName} ${profile.lastName}`
  const coachCityState = `${profile.city}, ${profile.state}`

  const seoTitle = `${coachName} | Golf Lessons | ${coachCityState}`
  const seoDescription = `${coachName} is a PGA Golf Professional who offers customized Golf Lessons and will work with you to create a winning strategy for your golf game.`
  const coachUrl = `${defaults.baseUrl}coach/${profile.customSlug}`

  const shouldIndexForSEO = profile.status === 'ACTIVE' && profile.coach.canPromoteContent

  return (
    <>
      <NextSeo
        noindex={!shouldIndexForSEO || defaults.noindexNofollow}
        nofollow={!shouldIndexForSEO || defaults.noindexNofollow}
        title={seoTitle}
        description={seoDescription}
        canonical={coachUrl}
        openGraph={{
          type: 'website',
          title: seoTitle,
          url: coachUrl,
          images: profile.profilePhoto && [{ url: profile.profilePhoto }],
        }}
      />
      <ProfilePage coach={profile} />
      <JsonLd {...{
        '@type': 'BreadcrumbList',
        itemListElement: [{
          '@type': 'ListItem',
          position: 1,
          item: {
            '@id': 'https://www.pga.com/',
            name: 'PGA Of America',
          },
        },
        {
          '@type': 'ListItem',
          position: 2,
          item: {
            '@id': 'https://www.pga.com/coach',
            name: 'Golf Lessons',
          },
        },
        {
          '@type': 'ListItem',
          position: 3,
          item: {
            '@id': `https://www.pga.com/coaches/${encodeURIComponent(profile.state)}/${encodeURIComponent(profile.city)}`,
            name: `${coachCityState}`,
          },
        },
        {
          '@type': 'ListItem',
          position: 4,
          item: {
            '@id': coachUrl,
            name: coachName,
          },
        },
        ],
      }}
      />
      <JsonLd {...{
        '@type': 'ProfessionalService',
        name: coachName,
        url: coachUrl,
        image: profile.profilePhoto || 'https://www.pga.com/images/person_icon.png',
        address: {
          '@type': 'PostalAddress',
          addressLocality: profile.city,
          addressRegion: profile.state,
          postalCode: profile.zipCode,
          addressCountry: 'United States',
        },
      }}
      />
    </>
  )
}

CoachPage.propTypes = {
  coach: PropTypes.object,
}

export default CoachPage
