import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  banner: {
    position: 'fixed',
    bottom: 'unset',
    display: 'flex',
    alignItems: 'center',
    height: '60px',
    width: '100%',
    backgroundColor: '#EBEFF5',
    zIndex: theme.zIndex.appBar - 1,

    [theme.breakpoints.only('xs')]: {
      top: 'unset',
      bottom: '0',
      backgroundColor: '#FFFFFF',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
  },
  button: {
    height: '2.5rem',
    padding: '6px 24px',
    marginLeft: '1rem',

    [theme.breakpoints.only('xs')]: {
      height: '3rem',
      lineHeight: '1rem',
      textAlign: 'center',
      '&:first-of-type': {
        marginLeft: 0,
      },
    },
  },
  spacer: {
    height: '74px',
    backgroundColor: 'white',

    [theme.breakpoints.only('xs')]: {
      height: '0px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',

    [theme.breakpoints.only('xs')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  externalLinkIcon: {
    marginLeft: '1rem',
    fontSize: '1.5rem',

    [theme.breakpoints.only('xs')]: {
      marginLeft: '0.5rem',
    },
  },
}))

export default useStyles
