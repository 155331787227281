import React from 'react'
import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import CoachArticles from './coach-articles'

const COACH_ARTICLES_QUERY = gql`
  query CoachArticles($person: cfPersonNestedFilter) {
    storyCollection(limit: 20, where: {person: $person}) {
      total,
      items {
            title
            headline
            slug
            cat {
              category
              series
            }
           sys {
              id
              publishedAt
              firstPublishedAt
            }
            image {
              contentType
              url
              description
              height
              title
              width
            }
            video {
              contentType
              url
              description
              height
              title
              width
            }
            heroVideoEmbed {
              provider
              id
            }
        }
    }
  }
`

function CoachPageWithArticlesData(props) {
  const { coach } = props
  const universalId = coach && coach.universalId

  return universalId && (
    <Query query={COACH_ARTICLES_QUERY} variables={{ person: { coachId: universalId } }}>
      {({
        loading, error, data,
      }) => {
        if (error) {
          return <Error error={error} displayErrorType="page" />
        }
        if (loading) return <LinearProgress color="secondary" />
        const response = data && data.storyCollection
        return response ? (
          <CoachArticles articles={data.storyCollection} firstName={coach.firstName} />
        ) : null
      }}
    </Query>
  )
}

CoachPageWithArticlesData.propTypes = {
  coach: PropTypes.object,
}

export default CoachPageWithArticlesData
