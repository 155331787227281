import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.secondary.main,
    overflowX: 'hidden',
  },
  bottomCTA: {
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

export default useStyles
