import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    height: '150',
    overflow: 'visible',
  },
  subtext: {
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '25px',
  },
  formInput: {
    borderRadius: '4px',
    background: 'white',
  },
  contactOverline: {
    color: theme.palette.primary.dark,
    fontSize: '12px',
    letterSpacing: '1px',
    fontWeight: '600',
  },
  disableSpacing: {
    paddingLeft: '0',
    paddingRight: '0',
  },
  coachInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '10rem',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  proceedingText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(0),
  },
}))
