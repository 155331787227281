/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import algoliasearch from 'algoliasearch/lite'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import EventsSlider from '../coach-offering-group-events/group-events-slider'
import useStyles from '../coach-offering-group-events/coach-offering-group-events.styles'
import ProgramCardAlgolia from './program-card'

const searchClient = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
const index = searchClient.initIndex(process.env.ALGOLIA_GROUP_EVENTS_INDEX_NAME)

const getPrograms = async (query, setPrograms) => {
  const { hits } = await index.search('', {
    filters: query,
    hitsPerPage: 100,
  })
  setPrograms(hits)
}

const CoachOfferingPrograms = ({ coach }) => {
  const algoliaTimestamp = (date = new Date()) => Math.floor(date / 1000)
  const query = `coach_id:${coach.coach.id} AND (tags:"PGA Jr. League" OR tags:"PGA Family Cup") AND registration_close_date_time_ts > ${algoliaTimestamp()}`
  const [programs, setPrograms] = useState([])

  useEffect(() => {
    getPrograms(query, setPrograms)
  }, [])

  const classes = useStyles()

  return programs.length > 0 ? (
    <Grid
      className={classes.offeringsRoot}
      container
      xs={11}
      md={11}
      lg={9}
    >
      <Grid item xs={12}>
        <Typography
          variant="overline"
          className={classes.offeringsOverline}
        >
          Hosted by {coach?.firstName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          Join a Program
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <EventsSlider
          events={programs}
          renderEvent={
            event => <ProgramCardAlgolia key={event.id} event={event} />
          }
        />
      </Grid>
    </Grid>
  ) : null
}

export default CoachOfferingPrograms
