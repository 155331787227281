import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { IconCheckMark } from '@components/atoms/icons'
import useStyles from './coach-services.styles'

const CoachServices = (props) => {
  const {
    services,
  } = props
  const styles = useStyles()

  return services && services.length ? (
    <Box className={styles.containerWrapper}>
      <Container>
        <Box className={styles.servicesSection}>
          <Box className={styles.titleWrapper}>
            <Typography variant="overline" style={{ color: '#a0d2fa' }}>Students & Lessons</Typography>
            <Typography variant="h4">Coaching Services</Typography>
          </Box>
          <Box className={styles.allServices}>
            {
              services.map((service) => {
                return (
                  <Box key={service.typeName} className={styles.serviceWrapper}>
                    <Typography variant="h5">{service.typeName}</Typography>
                    <Box className={styles.serviceEntry}>
                      {
                        service.services.map((value) => {
                          return (
                            <Box key={value.serviceName} className={styles.service}>
                              <IconCheckMark style={{ marginLeft: '-0.2em' }} />
                              <Typography variant="body2" className={styles.serviceName}>{value.serviceName}</Typography>
                            </Box>
                          )
                        })
                      }
                    </Box>
                  </Box>
                )
              })
            }
          </Box>
        </Box>
      </Container>
    </Box>
  ) : null
}

CoachServices.defaultProps = {}

export default CoachServices
