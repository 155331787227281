import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.gray,
    color: 'black',
  },
  paginationDash: {
    padding: `0 ${theme.spacing(1)}px`,
    fontSize: '1.25rem',
    lineHeight: 1,
    color: theme.overrides.MuiBreadcrumbs.separator.color,
  },
  paginationNumber: {
    fontSize: '0.875rem',
    letterSpacing: '0.1em',
    fontWeight: '600',
    color: theme.overrides.MuiBreadcrumbs.separator.color,
  },
  articlesWrapper: {
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    color: 'black',
    '& h4': {
      textAlign: 'center',
      margin: theme.spacing(2),
    },

    '& .slick-slider': {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      marginBottom: '-2rem',

      '& .slick-arrow': {
        position: 'relative',
        left: 'unset',
        right: 'unset',
        transform: 'unset',
        margin: '10px 48px',
        zIndex: 2,

        '&:before': {
          fontFamily: 'unset',
          content: 'unset',
        },
      },

      '& .slick-list': {
        order: 1,
        width: '100%',
      },
      '& .slick-prev': {
        order: 2,
        transform: 'scaleX(-1)',
      },
      '& .slick-next': {
        order: 3,
      },
      '& .slick-dots': {
        bottom: '-10px',
        backgroundColor: 'white',
        borderRadius: '5px',
        padding: '10px',
      },
      '& .slick-slide': {
        '& a:first-child': {
          height: '400px',
          [theme.breakpoints.down('xs')]: {
            height: '450px',
          },
        },
      },
      '& .slick-active.slick-current': {
        paddingLeft: 0,
      },
    },
  },
  itemWrapper: {
    width: '95% !important',
  },
}))

export default useStyles
