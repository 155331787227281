import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    color: 'black',
  },
  container: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  title: {
    color: theme.palette.grey.dark,
  },
  hr: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderTop: `1px solid ${theme.palette.grey.light}`,

    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  },
  experienceWrapper: {
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  experienceTitle: {
    width: '33%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  experienceType: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '66%',

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  experienceEntry: {
    display: 'flex',
    width: '50%',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  experienceName: {
    paddingLeft: theme.spacing(1),
    fontSize: '0.875rem',
    fontWeight: 'bold',
  },
}))

export default useStyles
