import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const ContentUnavailable = () => {
  return (
    <Box py={[8, 10, 12]} textAlign="center">
      <Typography variant="h4" component="h1" align="center">
        Content Unavailable
      </Typography>
      <Typography paragraph align="center">
        This is page is no longer available. Please contact your coach directly or email <Link href="mailto:support@pga.com">support@pga.com</Link>
      </Typography>
      <img src="/images/not-found.png" alt="" width={300} />
    </Box>
  )
}

export default ContentUnavailable
