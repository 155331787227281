import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import CoachProfileCTAButtons from '@components/coach-profile-cta-button'
import useStyles from './coach-profile-cta-banner.styles'

const CoachProfileCtaBanner = ({ coach }) => {
  const classes = useStyles()

  return (
    <>
      <Box className={classes.banner} py={2}>
        <Container mr={0}>
          <Box className={classes.buttonContainer}>
            <Box width="100%">
              <CoachProfileCTAButtons coach={coach} ctaPosition="banner" />
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={classes.spacer} />
    </>
  )
}

CoachProfileCtaBanner.propTypes = {
  coach: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    customSlug: PropTypes.string.isRequired,
  }).isRequired,
}

export default CoachProfileCtaBanner
