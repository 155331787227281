import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { useQuery } from '@apollo/client'

import { DateTime } from 'luxon'
import Error from '@components/error'
import LinearProgress from '@material-ui/core/LinearProgress'
import GroupEventCardLarge from '@components/group-event-card-large/group-event-card-large'
import EventsSlider from './group-events-slider'
import { GET_COACH_GROUP_EVENTS } from './coach-group-events.query'
import useStyles from './coach-offering-group-events.styles'

const CoachOfferingGroupEvents = ({ coach }) => {
  const today = DateTime.local().startOf('day').toISO()

  const { data, loading, error } = useQuery(GET_COACH_GROUP_EVENTS, {
    variables: {
      startDateTime: { from: today, to: DateTime.local().plus({ years: 1 }).toISODate() },
      coachId: coach.coach.id,
      includeCanceled: false,
    },
    context: { clientName: 'coach-tools' },
  })
  const classes = useStyles()
  if (loading) return <LinearProgress color="secondary" />
  if (error) {
    return <Error error={error} displayErrorType="page" />
  }
  const filteredGroupEvents = data?.groupEvents.filter((event) => {
    return !event.registrationClosed && !event.unlisted
  }) || []

  // let's create a filter that sorts the groupevents by the first sessions startDateTime
  const sortedAndFilteredGroupEvents = filteredGroupEvents.length > 0
  && filteredGroupEvents.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime))


  return sortedAndFilteredGroupEvents.length > 0 ? (
    <Grid
      className={classes.offeringsRoot}
      container
      xs={11}
      md={11}
      lg={9}
    >
      <Grid item xs={12}>
        <Typography
          variant="overline"
          className={classes.offeringsOverline}
        >
          Hosted by {coach?.firstName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">
          Join a Class or Event
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <EventsSlider
          events={sortedAndFilteredGroupEvents}
          renderEvent={
            event => <GroupEventCardLarge key={event.slug} event={event} />
          }
        />
      </Grid>
    </Grid>
  ) : null
}

CoachOfferingGroupEvents.propTypes = {
  coach: PropTypes.object.isRequired,
}


export default CoachOfferingGroupEvents
