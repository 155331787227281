/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SOCIAL_BG = '#E0E0E0'

const IconSocial = (props) => {
  switch (props.type) {
  case 'FB':
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="12" fill={props.fill || SOCIAL_BG} />
          <path d="M15.23,10H12.82c0-.36,0-.67,0-1,0-.47.22-.63.69-.63h1.71V6H13.58a2.83,2.83,0,0,0-2.95,2.38,13.28,13.28,0,0,0-.14,1.67H8.77V12.5h1.77V18h2.24l0-4.87V12.5h2.41Z" />
        </svg>
      </SvgIcon>
    )
  case 'IG':
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="12" fill={props.fill || SOCIAL_BG} />
          <path d="M13.32,19H9.64L9,19l-.72-.09a4.25,4.25,0,0,1-.78-.2,2.94,2.94,0,0,1-.66-.34,3.55,3.55,0,0,1-1.06-1,4,4,0,0,1-.59-1.46,4.67,4.67,0,0,1-.09-.74c0-.23,0-.46,0-.68s0-.43,0-.64,0-.62,0-.93V10.94c0-.12,0-.23,0-.34s0-.37,0-.55,0-.51,0-.76,0-.52.06-.77a4.54,4.54,0,0,1,.24-1,3,3,0,0,1,.44-.85,3.92,3.92,0,0,1,.51-.57,3.2,3.2,0,0,1,.55-.42,4.48,4.48,0,0,1,.58-.29A4.25,4.25,0,0,1,8.47,5.1c.25,0,.49,0,.74-.06s.56,0,.84,0h4.14l.78,0c.23,0,.45,0,.67.06a3.29,3.29,0,0,1,.61.12,4.41,4.41,0,0,1,.86.38,3.22,3.22,0,0,1,1.08,1,3.55,3.55,0,0,1,.5.94,4.49,4.49,0,0,1,.25,1.07c0,.25,0,.49,0,.73l0,.79v3.17c0,.17,0,.35,0,.52s0,.37,0,.55,0,.41,0,.61,0,.49-.07.74a3.36,3.36,0,0,1-.18.76,5.45,5.45,0,0,1-.25.55,2.12,2.12,0,0,1-.39.55c-.14.16-.3.31-.45.46a2,2,0,0,1-.43.33l-.26.13a4.7,4.7,0,0,1-1.76.47l-.54,0-.82,0h-.49ZM12,6.24H9.63c-.26,0-.52,0-.79,0a4.66,4.66,0,0,0-.73.13,2.26,2.26,0,0,0-.82.41,4.26,4.26,0,0,0-.43.42,2.33,2.33,0,0,0-.31.5,2.53,2.53,0,0,0-.12.36,2.42,2.42,0,0,0-.1.45c0,.1,0,.19,0,.28l0,.7c0,.24,0,.5,0,.74s0,.36,0,.54,0,.6,0,.9c0,.76,0,1.52,0,2.28,0,.15,0,.31,0,.46a5.93,5.93,0,0,0,.06.84,4.54,4.54,0,0,0,.19.81,2.2,2.2,0,0,0,.57.87,2.69,2.69,0,0,0,.84.52,4,4,0,0,0,.76.17c.33,0,.66.06,1,.07h4.39l.85,0a4.22,4.22,0,0,0,.61-.07,3.12,3.12,0,0,0,1-.34,2.07,2.07,0,0,0,.67-.58,2.7,2.7,0,0,0,.47-1.05,4.11,4.11,0,0,0,.08-.6c0-.23,0-.45,0-.67s0-.44,0-.66,0-.43,0-.65c0-.81,0-1.63,0-2.45,0-.31,0-.63,0-.94s0-.46,0-.69,0-.45-.09-.68a2.79,2.79,0,0,0-.18-.58A2.06,2.06,0,0,0,16.9,7,2.35,2.35,0,0,0,16,6.46a4.15,4.15,0,0,0-1.1-.18l-.54,0H12Z" />
          <path d="M12,15.12A3.1,3.1,0,1,1,15.11,12h0A3.11,3.11,0,0,1,12,15.12ZM10,12a2,2,0,1,0,2-2h0a2,2,0,0,0-2,2Z" />
          <path d="M16.65,8.35a1,1,0,1,1-2,0,1,1,0,0,1,2,0Z" />
        </svg>
      </SvgIcon>
    )
  case 'TWT':
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 1200 1227" preserveAspectRatio="xMidYMid meet" width="24" height="24">
          <circle cx="25" cy="25" r="50%" transform="translate(575, 590)" fill={props.fill || SOCIAL_BG} />
          <path
            transform="translate(360, 368.1) scale(0.4)"
            d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"
          />
        </svg>
      </SvgIcon>
    )
  case 'YT':
    return (
      <SvgIcon {...props}>
        <svg viewBox="0 0 24 24">
          <circle cx="12.14" cy="12.02" r="12" fill={props.fill || SOCIAL_BG} />
          <path d="M12.14,17l-1.89,0L8.83,17l-1.27-.08a6.53,6.53,0,0,1-.76-.08,3.12,3.12,0,0,1-.59-.19,1.6,1.6,0,0,1-.75-.75A3.15,3.15,0,0,1,5.21,15a10.86,10.86,0,0,1-.13-1.13c0-.59-.06-1.18-.08-1.77,0-.29,0-.59,0-.88s0-.58.05-.86a10.48,10.48,0,0,1,.17-1.48,4.28,4.28,0,0,1,.15-.51,1.57,1.57,0,0,1,1.09-1,3.61,3.61,0,0,1,.87-.16l.84-.06.85,0,1.89,0q1.17,0,2.34,0l1.57,0c.49,0,1,0,1.48.07.31,0,.62,0,.94.08a3.11,3.11,0,0,1,.67.16,1.57,1.57,0,0,1,.94.91,3.7,3.7,0,0,1,.23.85q.09.63.12,1.26c0,.51.05,1,.06,1.52,0,.34,0,.68,0,1s0,.53,0,.79A10.08,10.08,0,0,1,19,15.18a2.85,2.85,0,0,1-.14.5,1.58,1.58,0,0,1-1.08,1,3.71,3.71,0,0,1-.82.15l-1.11.08L14.68,17l-2.1,0h-.44Zm-1.41-2.88c.09,0,3.65-2.08,3.69-2.13-.14-.1-3.63-2.11-3.69-2.12Z" />
        </svg>
      </SvgIcon>
    )
  default:
    return (null)
  }
}

export default IconSocial
