import { CoachPageWithData } from '@layouts/coach'

const CoachPage = (props) => {
  return (
    <CoachPageWithData {...props} />
  )
}

CoachPage.getInitialProps = async ({ query }) => {
  return ({ query })
}

export default CoachPage
