import gql from 'graphql-tag'

export const GET_COACH_GROUP_EVENTS = gql`
  query getCoachGroupEvents(
    $coachId: ID,
    $startDateTime: DateTimeRangeInput,
    $includeCanceled: Boolean = false
  ) {
    groupEvents(
      coachId: $coachId,
      startDateTime:$startDateTime,
      includeCanceled: $includeCanceled,
      orderBy: [{field: START_DATE_TIME, direction: ASC}],
    ) {
      featuredImageUrl
      priceInCents
      slug
      title
      tags
      startDateTime
      endDateTime
      registrationClosed
      registrationCloseDateTime
      maxAttendance
      registrationCount
      unlisted
      registrationFull
      hasWaitlist
      requiresAccessCode
        coach {
          id
        }
      coachFacility {
        name
        city
        state
        id
        timezone
      }
      sessions {
        id
      }
    }
  }
`
