/* eslint-disable react/no-danger */
import PropTypes from 'prop-types'
import useStyles from './embed.styles'

const embedComponent = (props) => {
  const providers = {
    youtube: (id, autoplay) => (
      <iframe
        src={`https://www.youtube.com/embed/${id}?autoplay=${autoplay ? 1 : 0}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope;"
        allowFullScreen
        {...props}
      />
    ),
    vimeo: (id, autoplay) => (
      <iframe
        src={`https://player.vimeo.com/video/${id}?autoplay=${autoplay ? 1 : 0}`}
        frameBorder="0"
        allowFullScreen
        {...props}
      />
    ),
    contentful: (id, autoplay) => (
      <video
        width="100%"
        autoPlay={autoplay && ''}
        controls
        src={id}
        {...props}
      />
    ),
  }

  const {
    id,
    autoplay,
    provider,
  } = props
  const s = useStyles()
  const embed = providers[provider](id, autoplay)

  return (
    <div className={s.embed}>
      {embed}
    </div>
  )
}

const Embed = (props) => {
  return embedComponent(props)
}

Embed.propTypes = {
  provider: PropTypes.string,
  id: PropTypes.string,
  autoplay: PropTypes.bool,
}

export default Embed
