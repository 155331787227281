import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined'
import ButtonCta from '@components/atoms/button-cta'
import Box from '@material-ui/core/Box'
import useIsMobile from '@hooks/useIsMobile'
import useStyles from '../coach-profile-cta-banner/coach-profile-cta-banner.styles'
import { gtmEvent } from '@lib/gtm-utils'
import * as amplitude from '@amplitude/analytics-browser'
import { useRouter } from 'next/router'

const isPgaLink = (url) => {
  if (!url) return true

  const bookingUrl = new URL(url)
  return bookingUrl.hostname && /\.pga\.com/i.test(bookingUrl.hostname)
}
/* If 'Book Now' is enabled, always route to the coach's schedule page.
However, only display the words "book now" if they are bookable (active lessons). */
const CoachProfileCTAButtons = ({ coach, ctaPosition }) => {
  const {
    bookable, bookingUrl, firstName, lastName, displayName, customSlug, coachProfileId, introductionVideoUrl
  } = coach

  const coachName = (firstName && lastName) ? `${firstName} ${lastName}` : displayName
  const profileIsActive = coach.status === 'ACTIVE'
  const { bookNowEnabled } = coach?.coach.preferences
  const isExternalLink = !isPgaLink(bookingUrl)
  const [bookingWithReturnUrl, setBookingWithReturnUrl] = useState(bookingUrl)
  const classes = useStyles()
  const isMobile = useIsMobile()
  const router = useRouter()

  useEffect(() => {
    setBookingWithReturnUrl(isExternalLink ? bookingUrl : `${bookingUrl}?returnUrl=${encodeURIComponent(window.location.href)}`)
  }, [])
  const buttonProps = {
    className: classes.button,
    component: 'a',
    'data-gtm-profile-coach': coachName,
    'data-gtm-cta-position': ctaPosition,
  }
  const containedProps = {
    variant: 'contained',
    color: 'primary',
    ...buttonProps,
  }
  const outlinedProps = {
    variant: 'outlined',
    color: 'secondary',
    ...buttonProps,
  }

  const isBookable = bookable && bookingUrl

  const scrollToForm = () => {
    const formElement = document.getElementById('lead-form')
    const offset = isMobile ? 80 : 180
    if (formElement) {
      const formPosition = formElement.offsetTop
      window.scrollTo({ top: formPosition - offset, behavior: 'smooth' })
    }
  }

  const handleContactClick = () => {
    gtmEvent({
      event: 'click-contact-button',
      attributes: {
        coach_name: coachName,
        coach_profile_id: coachProfileId,
      },
    })
    scrollToForm()
  }

  const handleEventing = () => amplitude.track('click-book-now-button', {
    bookable,
    coach_profile_id: coachProfileId,
    coach_name: coachName,
    has_video: !!introductionVideoUrl,
    source: 'coach profile',
    page_path: router.asPath,
  })

  // If the coach has an external booking URL configured, link to that external site.
  if (bookNowEnabled && isExternalLink) {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          onClick={handleContactClick}
          variant="outlined"
          data-gtm-button-name="consolidated-contact-bookable-coach"
          {...outlinedProps}
        >
          Contact {firstName}
        </Button>
        <ButtonCta
          externalLink={bookingUrl}
          dataAttrs={{
            'data-gtm-button-name': 'book-now-ext',
          }}
          onClick={handleEventing}
          {...containedProps}
        >
          Book Now
          <OpenInNewOutlinedIcon className={classes.externalLinkIcon} titleAccess="External Link" />
        </ButtonCta>
      </Box>
    )
  }
  // If the coach has lessons to book, link to the coach's schedule page.
  if (bookNowEnabled && isBookable) {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          onClick={handleContactClick}
          variant="outlined"
          data-gtm-button-name="consolidated-contact-bookable-coach"
          {...outlinedProps}
        >
          Contact {firstName}
        </Button>
        <ButtonCta
          href={bookingWithReturnUrl}
          dataAttrs={{
            'data-gtm-button-name': 'book-now'
          }}
          onClick={handleEventing}
          {...containedProps}
        >
          Book Now
        </ButtonCta>
      </Box>
    )
  }
  // If the Coach user has Book now enabled,
  // without lessons to book(either no lesson types or all lesson types are inactive)
  // FF is FALSE indicates that we should send the user to the request coaching intake form on MyPGA
  const coachWantsToBeRequested = bookNowEnabled && !bookable
  if (coachWantsToBeRequested && profileIsActive) {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          onClick={handleContactClick}
          variant="outlined"
          data-gtm-button-name="consolidated-contact-bookable-coach"
          {...outlinedProps}
        >
          Contact {firstName}
        </Button>
      </Box>
    )
  }
  // Coach has no bookable or visible lessons
  // Book now is disabled
  // Intake form enabled (FF is TRUE)
  if (profileIsActive) {
    return (
      <Box className={classes.buttonContainer}>
        <Button
          onClick={handleContactClick}
          variant="outlined"
          data-gtm-button-name="consolidated-contact-bookable-coach"
          {...outlinedProps}
        >
          Contact {firstName}
        </Button>
      </Box>
    )
  }

  return null
}

CoachProfileCTAButtons.propTypes = {
  coach: PropTypes.shape({
    bookable: PropTypes.bool.isRequired,
    bookingUrl: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    customSlug: PropTypes.string.isRequired,
    introductionVideoUrl: PropTypes.string,
    coach: PropTypes.shape({
      lessonTypes: PropTypes.arrayOf(PropTypes.shape({
        status: PropTypes.string.isRequired,
      })),
      preferences: PropTypes.shape({
        bookNowEnabled: PropTypes.bool.isRequired,
      }),
    }).isRequired,
    status: PropTypes.string.isRequired,
  }).isRequired,
  ctaPosition: PropTypes.string.isRequired,
}

export default CoachProfileCTAButtons
