/* eslint-disable camelcase */
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Series from '@components/atoms/series'
import { PublishDate, PublishDateCard, Image } from '@components/atoms'
import Link from '@components/link'
import {
  CardMediumRoot,
  CardDate,
  VideoIcon,
  RootLink,
  Headline,
} from './card-medium.styles'

const variants = {
  carouseled: {
    height: [360, 380, 420, 440],
  },
  default: {
    height: [460, 460, 460, 460],
  },
}

const CardMedium = (props) => {
  const {
    hero_image,
    headline,
    image,
    cat,
    category,
    series,
    sys,
    video,
    bordered,
    variant,
    created_date,
  } = props

  const publishDate = sys ? sys.firstPublishedAt : created_date
  const combinedSeries = (cat && (cat.series || cat.category)) || series || category
  return (
    <RootLink>
      <Link {...props} variant="card" noBorder={!bordered}>

        <CardMediumRoot height={variants[variant].height}>
          <Box position="relative">
            {image && <Image src={`${image.url}?w=640&h=360&fit=fill`} alt={image.description || headline} />}
            {hero_image && <Image src={`${hero_image}?w=640&h=360&fit=fill`} alt={headline} />}
            {video && <VideoIcon />}
          </Box>
          <Box px={[2, 2, 3]} pt={1} pb={3} minHeight={100}>
            {combinedSeries != null ? <Series>{combinedSeries}</Series> : null}
            <Headline variant="h6">{headline}</Headline>
            {publishDate && (
              <CardDate>
                <PublishDateCard>
                  <PublishDate publishedAt={publishDate} />
                </PublishDateCard>
              </CardDate>
            )}
          </Box>
        </CardMediumRoot>

      </Link>
    </RootLink>
  )
}

CardMedium.propTypes = {
  __typename: PropTypes.string,
  bordered: PropTypes.bool,
  category: PropTypes.string,
  series: PropTypes.string,
  cat: PropTypes.shape({
    category: PropTypes.string,
    series: PropTypes.string,
  }),
  created_date: PropTypes.string,
  headline: PropTypes.string,
  hero_image: PropTypes.string,
  image: PropTypes.shape({
    description: PropTypes.string,
    url: PropTypes.string,
  }),
  nid: PropTypes.string,
  url: PropTypes.string,
  slug: PropTypes.string,
  sys: PropTypes.shape({
    id: PropTypes.string,
    firstPublishedAt: PropTypes.string,
  }),
  video: PropTypes.shape(),
  variant: PropTypes.oneOf(['default', 'carouseled']),
}

CardMedium.defaultProps = {
  variant: 'default',
  cat: {},
}

export default CardMedium
