import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  background: {
    border: '1px solid #D8D8D8',
    borderRadius: theme.shape.borderRadius * 8,
    backgroundColor: '#F6F6F6',
    padding: theme.spacing(6),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  root: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  body: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  calloutContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #D8D8D8',
    borderRadius: 12,
    backgroundColor: '#FFFFFF',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      borderRadius: 12,
    },
  },
  calloutText: {
    flex: 1,
    borderRight: '1px solid #D8D8D8',
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none',
      paddingRight: 0,
      marginRight: 0,
    },
  },
  optInText: {
    fontSize: '10px',
    textAlign: 'left',
  },
  calloutCheckbox: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  middleDivider: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      height: '1px',
      width: '100%',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
      display: 'block',
    },
  },
  topSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  iconWithDivider: {
    marginRight: theme.spacing(2),
  },
  header: {
    borderLeft: '0.5px solid #D8D8D8',
    paddingLeft: theme.spacing(2),
  },
  calloutFormControlLabel: {
    alignItems: 'flex-start',
    marginLeft: theme.spacing(1.5),

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  standardFormControlLabel: {
    alignItems: 'flex-start',

    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(0),
    },
  },
  checkbox: {
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(1.5),
  },
  standardHeader: {
    textAlign: 'left',
    marginBottom: theme.spacing(1),
  },
}))

export default useStyles
