import { styled } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PlayIcon from '@material-ui/icons/PlayArrowRounded'

export const CardMediumRoot = styled(Box)(({ theme }) => ({
  background: theme.palette.background.paper,
  height: '100%',
  position: 'relative',
  paddingBottom: theme.spacing(4),
  textDecoration: 'none',
  '& img': {
    display: 'block',
    maxWidth: '100%',
  },
}))

export const RootLink = styled('div')(() => ({
  height: '100%',
  '& a': {
    textDecoration: 'none',
    height: '100%',
  },
}))

export const Headline = styled(Typography)(() => ({
  fontSize: '20px',
}))

export const Series = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

export const CardDate = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    left: theme.spacing(2),
  },
}))

const iconSize = 48
const outerRing = 10
export const VideoIcon = styled(PlayIcon)(() => ({
  position: 'absolute',
  color: '#fff',
  top: `calc(50% - ${(iconSize + (outerRing * 2)) / 2}px)`,
  left: `calc(50% - ${(iconSize + (outerRing * 2)) / 2}px)`,
  height: `${iconSize + (outerRing * 2)}px`,
  width: `${iconSize + (outerRing * 2)}px`,
  padding: outerRing,
  border: '2px solid',
  borderRadius: '50%',
}))
