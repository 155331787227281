import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  containerWrapper: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    backgroundColor: 'white',
    color: 'black',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',

    '& span': {
      color: theme.palette.grey.dark,
    },
  },
  tabsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '& span:hover': {
      cursor: 'pointer',
    },
  },
  titleRoundedBG: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    fontSize: '0.75rem',
    lineHeight: '2',
    borderRadius: '1rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: theme.palette.grey.dark,
  },
  selectedTab: {
    backgroundColor: theme.palette.primary.dark,
    color: 'white',
  },
  skillsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingTop: '3rem',
  },
  skillItem: {
    display: 'flex',
    flex: '0 23%',
    marginBottom: '2%', // (100-23*4)/4 per row
    marginRight: '2%', // Add margin between elements
    '&:nth-child(4), &:last-child': {
      marginRight: 0, // Remove trailing margin
    },

    [theme.breakpoints.down('sm')]: {
      flex: '0 48%',
    },
    [theme.breakpoints.down(600)]: {
      flex: '0 100%',
      marginRight: 0,
    },

    borderBottom: `1.5px solid ${theme.palette.grey.light}`,
    alignItems: 'center',
    paddingBottom: theme.spacing(2),

    '& h6': {
      marginLeft: theme.spacing(2),
    },
  },
  toolItem: {
    flex: '0 31%',
    [theme.breakpoints.down(600)]: {
      flex: '0 100%',
      marginRight: 0,
    },
    '&:nth-child(4)': {
      marginRight: '2%', // Override skillItem margin styles
    },
    '&:nth-child(3)': {
      marginRight: 0, // Remove trailing margin
    },

    '& h6': {
      marginLeft: 0,
    },
  },
}))

export default useStyles
