import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Card from '@components/card-medium'
import { SliderArrow } from '@components/atoms'
import useStyles from './coach-articles.styles'

const CoachArticles = (props) => {
  const {
    articles,
    firstName,
  } = props
  const styles = useStyles()
  const [currentSlide, setCurrentSlide] = useState(1)

  // enable two-pass rendering (https://reactjs.org/docs/react-dom.html#hydrate) to allow
  // the client to render different numbers of slides based on the viewport (responsive mode)
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  const slides = articles.items.map(article => (
    <div className={styles.itemWrapper} key={article.sys.id}>
      <Card {...article} />
    </div>
  ))

  const settings = {
    dots: articles.items.length > 1,
    infinite: articles.items.length > 2,
    speed: 500,
    slidesToShow: 3,
    initialSlide: 0,
    nextArrow: <SliderArrow color="#9e9e9e" />,
    prevArrow: <SliderArrow color="#9e9e9e" />,
    responsive: isClient ? [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ] : null,
    appendDots: () => (
      <div>
        {/* current page in two digits */}
        <span
          className={styles.paginationNumber}
          style={{ color: 'black' }}
        >
          { String(currentSlide).padStart(2, '0') }
        </span>
        {/* dash between page numbers */}
        <span className={styles.paginationDash}>&#8212;</span>
        {/* total pages in two digits */}
        <span className={styles.paginationNumber}>
          { String(slides.length).padStart(2, '0') }
        </span>
      </div>
    ),
  }

  return slides && slides.length > 0 && (
    <Box className={styles.root}>
      <Container className={styles.articlesWrapper}>
        <Typography variant="h4">{firstName && `Articles written by ${firstName}`}</Typography>
        <Slider
          key={`coach-articles-slider-${isClient ? 'client' : 'server'}`}
          {...settings}
          afterChange={index => setCurrentSlide(index + 1)}
        >
          {slides}
        </Slider>
      </Container>
    </Box>
  )
}

CoachArticles.propTypes = {
  articles: PropTypes.object,
  firstName: PropTypes.string,
}

CoachArticles.defaultProps = {}

export default CoachArticles
