import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import useStyles from './styles'

const MarketingOptIn = ({ checked, onChange, useMarketingOptIn }) => {
  const classes = useStyles()

  const optInText = () => (
    <>
      {useMarketingOptIn === 'standard' && (
        <Typography variant="body2" className={classes.standardHeader}>
          Tee me up with PGA emails about events and tickets, swing tips, local golf programs, exclusive offers and more!
        </Typography>
      )}
      <Typography variant="body2" className={classes.optInText}>
        By checking this box, I confirm my request to be contacted by email by
        The Professional Golfers' Association of America ("PGA"), PGA Tournament
        Corporation, Inc. ("PTC") and/or PGA of America partners. I acknowledge
        that I can withdraw my consent at any time by following the
        'unsubscribe' instructions provided on any communication I receive from
        this service.
      </Typography>
    </>
  )

  const renderCalloutVariant = () => (
    <div className={classes.calloutContainer}>
      <div className={classes.calloutText}>
        <div className={classes.topSection}>
          <img
            className={classes.iconWithDivider}
            src="/images/logo-blue.svg"
            alt="PGA logo"
            width={32}
            height={32}
          />
          <Typography variant="h6" className={classes.header}>
            We Love This Game. How About You?
          </Typography>
        </div>
        <Typography variant="body2" className={classes.body}>
          Join the PGA of America's email community to hear about local golf
          programs, ticketing and volunteer opportunities, swing tips, exclusive
          offers and more!
        </Typography>
      </div>
      <Divider orientation="vertical" className={classes.middleDivider} />
      <div className={classes.calloutCheckbox}>
        <FormControlLabel
          className={classes.calloutFormControlLabel}
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
              className={classes.checkbox}
            />
          }
          label={optInText()}
          labelPlacement="end"
        />
      </div>
    </div>
  )

  return (
    <div className={classes.root}>
      {useMarketingOptIn === 'callout' ? renderCalloutVariant() : (
        <FormControlLabel
          className={classes.standardFormControlLabel}
          control={
            <Checkbox
              checked={checked}
              onChange={(e) => onChange(e.target.checked)}
              className={classes.checkbox}
            />
          }
          label={optInText()}
          labelPlacement="end"
        />
      )}
    </div>
  )
}

export default MarketingOptIn
